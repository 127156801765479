.loadingScreen {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #ffffff4f;
    box-sizing: border-box;
    position: relative;
}
.loadingScreen h1 {
    font-family: Poppins;
    font-size: 2vw;
    font-weight: 500;
    color: #000;
}
@media only screen and (max-width: 767px) {
    .loadingScreen h1 {
        font-size: 5vw;
    }
}