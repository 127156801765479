body { margin: 0; }
.login,
.login div,
.login div img,
.login form,
.login form label,
.login form input {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
}
.login {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    font-family: Poppins;
    max-width: 100%;
    max-height: 100%;
}
.login div img {
    width: 80%;
    height: auto;
}
.login form {
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
}
.login form label {
    width: 100%;
}
.login form label p {
    width: 20%;
    font-size: 1.2vw;
    font-weight: 400;
}
.login form input {
    width: 80%;
    border: 0px;
    border-bottom: 1px solid #000;
    background-color: transparent;
    height: 30px;
    font-family: Poppins;
    font-size: 1.2vw;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 0;
}
.login .left {
    width: 40%;
    height: 100%;
    padding: 2% 5%;
}
.login .right {
    width: 60%;
    height: 100%;
    background: linear-gradient(133deg, rgba(255,164,108,1) 1%, rgba(255,164,108,1) 3%, rgba(255,29,0,1) 100%);
}
.login form input[type="submit"] {
    margin-top: 20px;
    background-color: orangered;
    padding: 5%;
    color: #fff;
    border: 0;
    font-family: Poppins;
    font-weight: 400;
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 0;
    -webkit-border-radius: 0px;
}
.login form input[type="submit"]:hover {
    background-color: #252525;
}
.login .errorArea {
    margin-top: 20px;
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .login {
        overflow: hidden;
    }
    .login .left {
        width: 100%;
        padding: 5%;
    }
    .login div img, .login form label {
        width: 100%;
    }
    .login .right {
        display: none;
    }
    .login form label p, .login form input, .login form input[type="submit"] {
        font-size: 6vw;
    }
    .login form label p {
        width: 25%;
    }
    .login form input {
        width: 75%;
        padding: 0px 10px;
    }
    .login form input[type="submit"] {
        -webkit-border-radius: 0px;
        padding: 30px 60px;
    }
}